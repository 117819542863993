import { LocaleData, Messages } from '@lingui/core'

export const LOCALE_STORAGE_KEY = 'locale'

export type Locale = keyof typeof locales

export type Translate = { messages: Messages; localeData: LocaleData }

export const locales = {
  ar: 'Arabic',
  en: 'English',
  es: 'Spanish',
  id: 'Indonesian',
  ja: 'Japanese',
  ms: 'Malay',
  pt: 'Portuguese',
  th: 'Thai',
  tl: 'Tagalog',
  vi: 'Vietnamese',
}
export const defaultLocale: Locale = 'en'
