import React from 'react'

import { Trans } from '@lingui/macro'

import { Locale } from '../../../../../../shared/i18n'
import { SelectItem } from '../../../../../Select'

export const LOCALES: SelectItem<Locale>[] = [
  {
    label: <Trans>EN</Trans>,
    value: 'en',
  },
  {
    label: <Trans>AR</Trans>,
    value: 'ar',
  },
  {
    label: <Trans>MS</Trans>,
    value: 'ms',
  },
  {
    label: <Trans>TH</Trans>,
    value: 'th',
  },
  {
    label: <Trans>ID</Trans>,
    value: 'id',
  },
  {
    label: <Trans>VI</Trans>,
    value: 'vi',
  },
  {
    label: <Trans>TL</Trans>,
    value: 'tl',
  },
  {
    label: <Trans>ES</Trans>,
    value: 'es',
  },
  {
    label: <Trans>PT</Trans>,
    value: 'pt',
  },
  {
    label: <Trans>JA</Trans>,
    value: 'ja',
  },
]
